body {
  margin: 0;
  padding: 0;
  background-color: #680025;
  animation: bcolor 4s infinite;
  cursor: pointer;
}

:focus {
  outline: none !important;
}

@keyframes bcolor {
  0%,
  100% {
    background-color: #68000e;
  }
  50% {
    background-color: #860129;
  }
}

.main {
  position: relative;
  width: 100vw;
  height: 100vh;
}

canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 90%;
    height: 80%;

    line {
      fill: none;
      stroke: white;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 4px;
    }

    circle {
      fill: white;
    }

    line,
    circle {
      &.hidden {
        opacity: 0;
      }
    }
  }
}
